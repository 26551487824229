// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-article-js": () => import("./../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-fortolkning-js": () => import("./../src/templates/fortolkning.js" /* webpackChunkName: "component---src-templates-fortolkning-js" */),
  "component---src-templates-static-js": () => import("./../src/templates/static.js" /* webpackChunkName: "component---src-templates-static-js" */)
}

