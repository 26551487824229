/**
 * @providesModule theme
 * @flow
 */

/**
 * Theme contains variables shared by styles of multiple components.
 */

import hex2rgba from 'hex2rgba';

const colors = {
  lighter: '#373940', // light blue
  dark: '#282c34', // dark blue
  darker: '#20232a', // really dark blue
  brand: '#c4ae8f', // '#61dafb', // electric blue
  brandLight: '#f48c81', // '#bbeffd',
  text: '#1a1a1a', // very dark grey / black substitute
  subtle: '#6d6d6d', // light grey for text
  subtleOnDark: '#999',
  divider: '#ececec', // very light grey
  note: '#c4ae8f', // '#ffe564', // yellow
  error: '#ff6464', // yellow
  white: '#ffffff',
  black: '#000000',

  brandBackground: '#e7eff2', // lejeloven, really light blue
  brandHighlight: '#f78b80' // lejeloven, really light red
};

const SIZES = {
  xsmall: { min: 0, max: 599 },
  small: { min: 600, max: 779 },
  medium: { min: 780, max: 979 },
  large: { min: 980, max: 1279 },
  xlarge: { min: 1280, max: 1339 },
  xxlarge: { min: 1340, max: Infinity },

  // Sidebar/nav related tweakpoints
  largerSidebar: { min: 1100, max: 1339 },
  sidebarFixed: { min: 2000, max: Infinity }
};

type Size = $Keys<typeof SIZES>;

const media = {
  between(smallKey: Size, largeKey: Size, excludeLarge: boolean = false) {
    if (excludeLarge) {
      return `@media (min-width: ${
        SIZES[smallKey].min
      }px) and (max-width: ${SIZES[largeKey].min - 1}px)`;
    } else {
      if (SIZES[largeKey].max === Infinity) {
        return `@media (min-width: ${SIZES[smallKey].min}px)`;
      } else {
        return `@media (min-width: ${SIZES[smallKey].min}px) and (max-width: ${
          SIZES[largeKey].max
        }px)`;
      }
    }
  },

  greaterThan(key: Size) {
    return `@media (min-width: ${SIZES[key].min}px)`;
  },

  lessThan(key: Size) {
    return `@media (max-width: ${SIZES[key].min - 1}px)`;
  },

  size(key: Size) {
    const size = SIZES[key];

    if (size.min == null) {
      return media.lessThan(key);
    } else if (size.max == null) {
      return media.greaterThan(key);
    } else {
      return media.between(key, key);
    }
  },

  match(mediaQuery: string) {
    if (typeof window !== 'undefined' && window.matchMedia) {
      return window.matchMedia(mediaQuery.replace('@media ', ''));
    }
    return null;
  }
};

const fonts = {
  header: {
    fontSize: 60,
    lineHeight: '65px',
    fontWeight: 700,

    [media.lessThan('medium')]: {
      fontSize: 36,
      lineHeight: '45px'
    }
  },
  small: {
    fontSize: 14
  }
};

// Shared styles are generally better as components,
// Except when they must be used within nested CSS selectors.
// This is the case for eg markdown content.
const linkStyle = {
  // backgroundColor: hex2rgba(colors.brandLight, 0.3),
  borderBottom: `1px solid ${hex2rgba(colors.black, 0.2)}`,
  color: '#4078c0', // colors.text,

  ':hover': {
    backgroundColor: hex2rgba('#4078c0', 0.1), // colors.brandLight,
    borderBottomColor: '#4078c0' // colors.text
  }
};
const sharedStyles = {
  link: linkStyle,

  articleLayout: {
    container: {
      display: 'flex',
      minHeight: 'calc(100vh - 300px)',
      [media.greaterThan('sidebarFixed')]: {
        maxWidth: 840,
        marginLeft: 'auto',
        marginRight: 'auto'
      }
    },
    content: {
      marginTop: 40,
      marginBottom: 120,

      [media.greaterThan('medium')]: {
        marginTop: 50
      },

      [media.greaterThan('small')]: {
        '& p': {
          textAlign: 'justify'
        }
      }
    },
    sidebar: {
      display: 'flex',
      flexDirection: 'column',

      [media.between('small', 'sidebarFixed')]: {
        borderLeft: '1px solid #ececec',
        marginLeft: 80
      },

      [media.between('small', 'largerSidebar')]: {
        flex: '0 0 200px',
        marginLeft: 80
      },

      [media.between('small', 'medium')]: {
        marginLeft: 40
      },

      [media.greaterThan('largerSidebar')]: {
        flex: '0 0 300px'
      },

      [media.greaterThan('sidebarFixed')]: {
        position: 'fixed',
        right: 0,
        width: 500,
        zIndex: 2
      }
    },

    editLink: {
      color: colors.subtle,
      borderColor: colors.divider,
      transition: 'all 0.2s ease',
      transitionPropery: 'color, border-color',
      whiteSpace: 'nowrap',
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',

      ':hover': {
        color: colors.text,
        borderColor: colors.text
      }
    }
  },

  markdown: {
    lineHeight: '25px',

    '& .gatsby-highlight': {
      marginTop: 25,
      marginLeft: -30,
      marginRight: -30,
      marginBottom: 25,
      paddingLeft: 15,
      paddingRight: 15,

      [media.lessThan('small')]: {
        marginLeft: -20,
        marginRight: -20,
        borderRadius: 0
      }
    },

    '& a:not(.anchor):not(.gatsby-resp-image-link)': linkStyle,

    // '& > p:first-child': {
    //   fontSize: 18,
    //   fontWeight: 300,
    //   color: colors.subtle,
    //
    //   [media.greaterThan('xlarge')]: {
    //     fontSize: 24
    //   },
    //
    //   '& a, & strong': {
    //     fontWeight: 400
    //   }
    // },

    '& p': {
      marginTop: 30,
      fontSize: 17,
      lineHeight: 1.7,
      maxWidth: '42em',

      '&:first-of-type': {
        marginTop: 15
      },

      '&:first-child': {
        marginTop: 0
      },

      [media.lessThan('large')]: {
        fontSize: 16,
        marginTop: 25
      }
    },

    '& h3 + p, & h3 + p:first-of-type': {
      marginTop: 20
    },

    '& p > code, & li > code': {
      background: hex2rgba(colors.note, 0.2),
      color: colors.text
    },

    '& p > code, & li > code, & p > a > code, & li > a > code': {
      padding: '0 3px',
      fontSize: 16,
      wordBreak: 'break-word'
    },

    '& hr': {
      height: 1,
      marginBottom: -1,
      border: 'none',
      borderBottom: `1px solid ${colors.divider}`,
      marginTop: 40,

      ':first-child': {
        marginTop: 0
      }
    },

    '& h1': {
      lineHeight: 1.2,

      [media.size('xsmall')]: {
        fontSize: 30
      },

      [media.between('small', 'large')]: {
        fontSize: 45
      },

      [media.greaterThan('xlarge')]: {
        fontSize: 60
      }
    },

    '& h2': {
      borderTop: `1px solid ${colors.divider}`,
      marginTop: 44,
      paddingTop: 40,
      lineHeight: 1.2,

      ':first-child': {
        borderTop: 0,
        marginTop: 0,
        paddingTop: 0
      },

      [media.lessThan('large')]: {
        fontSize: 20
      },
      [media.greaterThan('xlarge')]: {
        fontSize: 35
      }
    },

    '& hr + h2': {
      borderTop: 0,
      marginTop: 0
    },

    '& h3': {
      paddingTop: 45,

      [media.greaterThan('xlarge')]: {
        fontSize: 25,
        lineHeight: 1.3
      }
    },

    '& h2 + h3, & h2 + h3:first-of-type': {
      paddingTop: 30
    },

    '& h4': {
      fontSize: 20,
      color: colors.subtle,
      lineHeight: 1.3,
      marginTop: 50,
      fontWeight: 400
    },

    '& h4 + p': {
      marginTop: 20
    },

    '& ol, & ul': {
      marginTop: 20,
      fontSize: 16,
      color: colors.text,
      paddingLeft: 20,

      '& p': {
        fontSize: 16,
        marginTop: 20,
        // lineHeight: 1.2
        '&:first-of-type': {
          marginTop: 0
          // lineHeight: 1.2
        }
      },

      '& li': {
        marginTop: 20
      },

      '& li.button-newapp': {
        marginTop: 0
      },

      '& ol, & ul': {
        marginLeft: 20
      }
    },

    '& img': {
      maxWidth: '100%'
    },

    '& ol': {
      listStyle: 'decimal'
    },

    '& ul': {
      listStyle: 'disc'
    },

    '& blockquote': {
      backgroundColor: hex2rgba(colors.note, 0.3),
      borderLeftColor: colors.note,
      borderLeftWidth: 9,
      borderLeftStyle: 'solid',
      padding: '20px 45px 20px 26px',
      marginBottom: 30,
      marginTop: 20,
      marginLeft: -30,
      marginRight: -30,

      [media.lessThan('small')]: {
        marginLeft: -20,
        marginRight: -20
      },

      '& p': {
        marginTop: 15,

        '&:first-of-type': {
          // fontWeight: 700,
          marginTop: 0
        },

        '&:nth-of-type(2)': {
          marginTop: 0
        }
      }
    },

    '& .gatsby-highlight + blockquote': {
      marginTop: 40
    },

    '& .responsive-table': {
      margin: '0.5em 0 1em',
      [media.lessThan('small')]: {
        overflow: 'scroll',
        maxWidth: 'calc(100vw - 40px)'
      },
      [media.between('small', 'medium')]: {
        overflow: 'scroll',
        maxWidth: 'calc(100vw - 280px)'
      },

      '& table': {
        borderCollapse: 'collapse'
      },

      /* Zebra striping */
      '& tr:nth-of-type(odd)': {
        background: '#eee'
      },

      '& th': {
        background: '#333',
        color: 'white',
        fontWeight: 'bold'
      },

      '& td, & th': {
        padding: '6px',
        border: '1px solid #ccc',
        textAlign: 'left'
      }
    },

    '& .math-table': {
      '& td': {
        textAlign: 'right'
      },
      '& td:first-child': {
        textAlign: 'left'
      }
    }
  }
};

export { colors, fonts, media, sharedStyles };

export function layoutHasSidebar(location: Location) {
  return /^\/(fortolkning|lejer|udlejer|huslejenaevn|en$)/.test(
    location.pathname
  );
}
