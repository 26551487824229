'use strict';

// Import global styles
require('normalize.css');
// require('glamor/reset');
require('./src/css/reset.css');
require('./src/prism-styles');
// require('./src/css/algolia.css');
// A stub function is needed because gatsby won't load this file otherwise
// (https://github.com/gatsbyjs/gatsby/issues/6759)
exports.onClientEntry = () => {};
